<template>
  <div></div>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import { API_STATUS } from "@/constants/apiStatus";
import { fetchUserTracksLists } from "@/api/userTracksListApi";
import { debounce } from "lodash-es";
import { LoadMore } from "@/components/common";
export default {
  name: "ListtRacksProvider",
  components: {
    LoadMore,
  },
  props: {
    query: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lists: [],
      page: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>