import { db, firebase, functions } from '@/config/firebase.config.js';

export const fetchUserTracksLists = async ({
  query,
  page = 0,
  perPage = 20,
} = {}) => {
  return functions
    .httpsCallable('fetchUserTracksLists')({ query, page, perPage })
    .then((res) => {
      return res.data.data;
    });
};

export const searchUserListTracks = async ({
  query,
  page = 0,
  perPage = 20,
  listId,
} = {}) => {
  return functions
    .httpsCallable('searchUserListTracks')({ query, page, perPage, listId })
    .then((res) => {
      return res.data.data;
    });
};

export const createUserTracksList = async ({ name }) => {
  return functions
    .httpsCallable('createUserTracksList')({ name })
    .then((res) => {
      return res.data.data;
    });
};

export const addTracksToUserList = async ({ objectIDs, listId }) => {
  return functions
    .httpsCallable('addTracksToUserList')({
      objectIDs,
      listId,
    })
    .then((res) => {
      return res.data.data;
    });
};

export const updateUserListName = async ({ name, listId }) => {
  return functions
    .httpsCallable('updateUserListName')({
      name,
      listId,
    })
    .then((res) => {
      return res.data.data;
    });
};

export const deleteUserList = async ({ listId }) => {
  return functions
    .httpsCallable('deleteUserList')({
      listId,
    })
    .then((res) => {
      return res.data.data;
    });
};

export const deleteUserListTrack = async ({ listId, objectID }) => {
  return functions
    .httpsCallable('deleteUserListTrack')({
      listId,
      objectID,
    })
    .then((res) => {
      return res.data.data;
    });
};
